import React from 'react';
import './Contracts.css';
import {Routes, Route} from 'react-router-dom';

import Grouped from './grouped/Grouped';
import Individuals from './individuals/Individuals';


const Contracts = () => {
	return (
		<div className="app-flex full-w wrapper content">
				 <Routes>
					    <Route path="/" element={<Grouped/>}/>
					    <Route path="/*" element={<Individuals/>}/>
				  </Routes>
		</div>
	)
}

export default Contracts