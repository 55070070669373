import React, {useState} from 'react';
import './subFolder.css';

import {LuFileUp, LuFile} from 'react-icons/lu';
import {AiOutlineCloudDownload, AiOutlineDelete} from 'react-icons/ai';
import {RxCross2} from 'react-icons/rx';

import fetch from '../../context/useFetch';
import download from '../../context/useDownload';
import Modal from '../Modal/Modal';


const SubFolder = ({selectedFolder ,setSelectedFolder, fetchAttachements}) => {

	const [data, setData] = useState(selectedFolder?.files);
	
	const [showBakcDrop, setShowBackDrop] = useState(false);
	const [modal, setModal] = useState(false);

	const [selected, setSelected] = useState(null);
	const [status, setStatus] = useState(null);

	const [showBakcDropModal, setShowBackDropModal] = useState(false);
	const [modalUpload, setModalUpload] = useState(false);

	const select = (file) => {
		setShowBackDrop(true);
		setSelected(file);
		setTimeout(()=>{ setModal(true) },50)
	}

	const deSelect = () => {
		setModal(false);
		setSelected(null);
		setTimeout(()=>{ setShowBackDrop(false) },50)
	}

	const showModal = () => {
		setShowBackDropModal(true);
		setTimeout(()=>{ setModalUpload(true) },50)
	}

	const hideModal = () => {
		setModalUpload(false);
		setTimeout(()=>{ setShowBackDropModal(false) },50)
	}

	const updateData = (operation ,num,  file) =>{

		if(operation === 'add' && (num !== undefined || num !== '' || num !== null)){

				let newData = [...data];

				let newFile = `contract_number_${num}_${data.length + 1}.${file.type.split('/')[1]}`;

				newData.push(newFile);
								
				setData(newData);
		}

		if(operation === 'pop' && (num !== undefined || num !== '' || num !== null)){

				let filtered = data.filter(file=>{
					return file !== num
				})
								
				setData(filtered);
		}
	}


	const deleteFile = () =>{
		setStatus('pending');

		fetch(`/api/delete_attachment/?contract_num=${selectedFolder.contractNumber}&attachment=${selected}`, {method:'DELETE'})
			.then((res)=>{
				if(res){
					setStatus('true');
					updateData('pop', selected);
					setTimeout(()=>{setStatus(null)}, 1500);
					setTimeout(()=>{deSelect()}, 2500);
					

				}else{
					setStatus('false')
					setTimeout(()=>{setStatus(null)}, 3000);
				}
			})
			.catch((err)=>{
				setStatus('false');
				setTimeout(()=>{setStatus(null)}, 3000);
			})
	}

	const downloadFile = async () =>{
		const data = await download(selected);

		if(data?.status === 200){
			const blob = new Blob([data.data]);
			const objectUrl = URL.createObjectURL(blob);

			const link = document.createElement('a');
			link.href = objectUrl;
			link.setAttribute('download', selected);
			document.body.appendChild(link);
			link.click();
		} else{
			setStatus('false');
			setTimeout(()=>{setStatus(null)}, 3000);
		}
	}


	return (
		<div className="folder_main">

			<Modal modal={modalUpload} showBakcDrop={showBakcDropModal} hideModal={hideModal} fetchAttachements={fetchAttachements} updateData={updateData} fileName={selectedFolder?.contractNumber}/>

			<div className={`back-drop ${showBakcDrop && 'show-backdrop'}`}></div> 
			<div className={`modal app-flex ${modal && 'show_modal'}`} style={{gap:'15px'}}>
				{status  === 'pending' &&  <h3 className="wait">Deleting....</h3>}
				{status === 'true' &&  <h3 className="success">File seccussfully deleted</h3>}
				{status === 'false' &&  <h3 className="err">Error deleting/downloading the files, please try again.</h3>}

				{status === null &&
					<>
						<RxCross2 className="cross-icon" onClick={()=>deSelect()}/>
						<h5 className="text app-flex full">{selected}</h5>
						<button className="btn full pointer app-flex" style={{gap:'10px'}} onClick={()=>downloadFile()}> 
							<AiOutlineCloudDownload className="icon_m"/>
							<b>Download</b>
						</button>
						<button className="btn full pointer app-flex" style={{gap:'10px'}} onClick={()=>deleteFile()}>
							<AiOutlineDelete className="icon_m"/>
							<b>Delete</b>
						</button>
					</>}
			</div>

			<div className="app-flex head">
				<h4 className="title">
				  Vous Fichiers - {selectedFolder?.contractNumber}
				</h4>

				<div className="right_margin app-flex" style={{gap:'15px'}}>

					<div className="uploader add  nom app-flex" onClick={()=>{showModal()}}>
							<LuFileUp className="add-icon"  />
					</div>

					<button className="add" onClick={()=>setSelectedFolder('')}>
						  Retour
					</button>
				</div>
			</div>
			<div className="app-flex files2_container">
					{data.length > 0 
					?  data.map((file,i)=>(
							<div className="folder app-flex" key={i} onClick={()=>select(file)}>
									<LuFile className="f_icon"/>
									<h4 className="f_name">{file}</h4>
							 </div>
							))
				    : <p>You have no files in this folder.</p>}
			</div>
		</div>
	)
}

export default SubFolder