import React from 'react';
import './Files.css';

import {Routes, Route} from 'react-router-dom';

import Folder from '../../../../components/folder/Folder';

const Files = () => {
	

	return (
		<section className="files_container">
				<Routes>
			        <Route path="/" element={<Folder />}/>
				</Routes>
		</section>
	)
}

export default Files