import React, {useState, useContext} from 'react';
import './login.css';
import {useNavigate} from 'react-router-dom';

import {AiOutlineUser, AiFillEye, AiFillEyeInvisible} from 'react-icons/ai';

import logo from '../../assets/logo.png';
import AuthContext from "../../context/AuthContext";

const Login = () => {
	const navigate = useNavigate();
	const { loginUser } = useContext(AuthContext);

	const[show, setShow] = useState(true);

	const [username, setUsername] = useState(null);
	const [pass, setPass] = useState(null);

	const [wrongCreds, setWrongCreds] = useState(false);

	const login =async ()=>{
		let isLogged = await loginUser({username:username, password:pass});
		if(isLogged){
			navigate('/');
			return 0;
		} else {
			setWrongCreds(true);

			setTimeout(()=>{setWrongCreds(false)},2000)
			return 1
		}
	}


	return (
		<div className="app-flex wrap full-v" style={{height:"100vh"}}>
			<img src={logo} className="logo" alt="logo"/>
			{wrongCreds &&
				<div className="error">
					<h5>Wrong credentials.</h5>
				</div>}
			<div className="form app-flex space">
				<label for="username" className="input">
					<input id="username" placeholder="username" type="text" className="sub-input" onChange={(e)=>setUsername(e.target.value)}/>
					<AiOutlineUser className="icon"/> 
				</label>
				<label for="password" className="input">
					<input id="password" placeholder="password" type={show ? 'password' : 'text'} className="sub-input" onChange={(e)=>setPass(e.target.value)}/>
					{show ? <AiFillEye className="icon pointer" onClick={()=>setShow(!show)}/>  : <AiFillEyeInvisible className="icon pointer" onClick={()=>setShow(!show)}/> }
				</label>
			</div>

			<button className="btn pointer" onClick={()=>login()}>
				Login
			</button>
		</div>
	)
}

export default Login