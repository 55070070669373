import React, {useState, useEffect, useContext} from 'react';
import './Sidebar.css';
import {NavLink} from 'react-router-dom';
import AuthContext from "../../context/AuthContext";


import {LuClipboardList, LuListChecks, LuFolderDown, LuFolder} from 'react-icons/lu';
import {CgLogOff} from 'react-icons/cg';



  const getWindowSize = () => {
    const {innerWidth, innerHeight} = window;
    return {innerWidth, innerHeight};
  }

const Sidebar = () => {
  const { logoutUser } = useContext(AuthContext);
  
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [shrink, setShrink] = useState(true);

  useEffect(() => {
    const handleWindowResize = () =>{
      setWindowSize(getWindowSize());
    }
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);


  useEffect(() => {
    if(windowSize.innerWidth < 1160){
      setShrink(true);
    } else {
      setShrink(true);
    }
  }, [windowSize]);

	return (
		<section className={`main-sidebar ${shrink && 'shrink'}`}>
			<NavLink
             to="/" 
             className={({isActive})=>`${isActive && 'isActive'} toleft abstract app-flex ${shrink && 'small'}`}>
                <LuClipboardList className="side-icon"/>
                  {!shrink && <h4 className="tab">Registre un client</h4>}
            </NavLink>

            <NavLink
             to="/contracts" 
             className={({isActive})=>`${isActive && 'isActive'} toleft abstract app-flex ${shrink && 'small'}`}>
                <LuListChecks className="side-icon"/>
                  {!shrink && <h4 className="tab">List des contracts</h4>}
            </NavLink>

            <NavLink
             to="/Saved_Contracts" 
             className={({isActive})=>`${isActive && 'isActive'} toleft abstract app-flex ${shrink && 'small'}`}>
                <LuFolder className="side-icon"/>
                  {!shrink && <h4 className="tab">Contracts</h4>}
            </NavLink>

            <NavLink
             to="/Saved_Devis" 
             className={({isActive})=>`${isActive && 'isActive'} toleft abstract app-flex ${shrink && 'small'}`}>
                <LuFolder className="side-icon"/>
                  {!shrink && <h4 className="tab">Devis</h4>}
            </NavLink>

            <NavLink
             to="/Files" 
             className={({isActive})=>`${isActive && 'isActive'} toleft abstract app-flex ${shrink && 'small'}`}>
                <LuFolderDown className="side-icon"/>
                  {!shrink && <h4 className="tab">Fichiers</h4>}
            </NavLink>

            <NavLink
             onClick={()=>logoutUser()}
             className={`isActive abstract app-flex float ${shrink && 'small'}`}>
                <CgLogOff className="side-icon"/>
                  {!shrink && 'Logout'}
            </NavLink>

		</section>
	)
}

export default Sidebar