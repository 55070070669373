import React from 'react';
import './Table.css';

import {useNavigate} from 'react-router-dom';

export const InputCell = ({label, data, setData, value}) =>{

	return(
		<tr className="data-cells">
					<td className="table-keys cell">{label}</td>
					
					{	
					label !== "Numero Loyer" && !value
						 && <input className="value cell" type="text" placeholder={`Entrée ${label}`} onChange={(e)=>setData(e.target.value)}/>
				 	}
				 	{
				 	label === 'Numero Loyer'
				 		&& <input className="value cell" type="text" value={label === 'Numero Loyer' && 1} placeholder={`Entrée ${label}`} onChange={(e)=>setData(e.target.value)}/>
				 	}
				 	{
				 	value && 
				 		<input className="value cell" type="text" value={data === null ? '' : data} placeholder={`Entrée ${label}`} onChange={(e)=>setData(e.target.value)}/>
				 	}
		</tr>
		)
}

export const RegularCell = ({label, data}) =>{
	return(
		<tr className="data-cells">
					<td className="table-keys cell">{label}</td>
					<td className="value cell">{data}</td>
		</tr>
		)
}

export const Cell=({data, point, more})=>{
	const navigate = useNavigate();

	return(
		<>
		<tr className={`data-cells ${point && 'pointer pointing'}`} onClick={()=>point && navigate(`${data.numero_contract}`)}>
				<td className={`value2 cell2 ${more && 'cell_update'}`} >{data.numero_contract}</td>
				<td className={`value2 cell2 ${more && 'cell_update'}`} >{data.numero_rc}</td>
				<td className={`value2 cell2 ${more && 'cell_update'}`} >{data.date}</td>
				<td className={`value2 cell2 ${more && 'cell_update'}`} >{data.loyer_ht}</td>
				<td className={`value2 cell2 ${more && 'cell_update'}`} >{data.duration}</td>

				{more?.length > 0 
				&& more.map((cell,i)=>(
					<td className="value2 cell2 cell_update">{cell === null ? '-' : cell}</td>
					))}

				<td className={`value2 cell2 ${more && 'cell_update'}`}>{data.note}</td>

		</tr>
		</>
	)
}

const Table = ({children, width}) => {
	return (
		<>
			<table cellPadding="0" cellSpacing="0" style={width && {width:'48.6%'}}>
				<tbody>
					{children}
				</tbody>
			</table>
		</>
	)
}

export default Table;