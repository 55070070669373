import React from 'react';
import './Loading.css';

import {BiLoaderAlt} from 'react-icons/bi';

const Loading = () => {
	return (
		<div className="float-loading app-flex">
			<BiLoaderAlt className="loading"/>
		</div>
	)
}

export default Loading