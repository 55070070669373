import React, {useState, useEffect} from 'react';
import './main.css';
import {Routes, Route, useLocation} from 'react-router-dom';

import Header from '../../components/header/header';
import Sidebar from '../../components/sidebar/Sidebar';
import Form from './subs/form/Form';
import Contracts from './subs/contracts/Contracts';
import Files from './subs/files/Files';
import ContractsList from '../../components/Contracts_Devis_List/Contracts_List';

import fetch from '../../context/useFetch';


const getWindowSize = () => {
    const {innerWidth, innerHeight} = window;
    return {innerWidth, innerHeight};
  }

const Main = () => {

  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [shrink, setShrink] = useState(false);

  const location  = useLocation();

  useEffect(() => {
    const handleWindowResize = () =>{
      setWindowSize(getWindowSize());
    }
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);


  useEffect(() => {
    if(windowSize.innerWidth < 620){
      setShrink(true);
    } else {
      setShrink(false);
    }
  }, [windowSize]);

  useEffect(() => {
  	
  	fetch('/api/all_contracts/', {method:'GET'})	
			.then(data=>{return 0})
			.catch(err=>{return 0})

  }, [location])

	return (
		<section className="main wrapper">
			<Header/>
			<div className="container full-w left" style={{gap:'10px'}}>
				<Sidebar/>
				<div className="box">
					 {shrink 
					 	? <p className="full-w app-flex" style={{marginTop:'50px', textAlign:'center'}}>
					 		Phone view is not supported, please open it on laptop/desktop.
					 	  </p>
					 	: <Routes>
					        <Route path="/" element={<Form/>}/>
					        <Route path="/contracts/*" element={<Contracts/>}/>
					        <Route path="/Files/*" element={<Files/>}/>
					        <Route path="/Saved_Contracts/*" element={<ContractsList title={'Contracts'} url={'facture'}/>}/>
					        <Route path="/Saved_Devis/*" element={<ContractsList title={'Devis'} url={'devis'}/>}/>

					      </Routes>
					  }
				</div>
			</div>
		</section>
	)
}

export default Main;

  