import React from 'react';
import './header.css'
import logo from '../../assets/logo.png';

const Header = () => {
	return (
		<div className="header app-flex">
			<img src={logo} className="header-logo" alt="logo"/>
		</div>
	)
}

export default Header