import React, {useState, useEffect} from 'react';
import './Contracts_List.css';

import {LuFile} from 'react-icons/lu';
import fetch from '../../context/useFetch';

import {RxCross2} from 'react-icons/rx';
import {AiOutlineDelete, AiOutlineEdit} from 'react-icons/ai';
import {CgArrowsExpandUpRight} from 'react-icons/cg';
import {MdDriveFileRenameOutline, MdOutlineKeyboardBackspace} from 'react-icons/md';

const Contracts_List = ({title, url}) => {

	const [data, setData] = useState([]);
	const [newName, setNewName] = useState('');

	const [showBakcDrop, setShowBackDrop] = useState(false);
	const [modal, setModal] = useState(false);

	const [selected, setSelected] = useState(null);
	const [status, setStatus] = useState(null);

	const getList = () =>{
		try{
			fetch('/api/files/list_all/', {method:'GET'})	
				.then(res=> {
					setData(res);
				})
				.catch(err=>console.log(err))
		} catch (e){
			console.log(e)
		} 
	}

	const select = (file) => {
		setShowBackDrop(true);
		setSelected(file);
		setTimeout(()=>{ setModal(true) },50)
	}

	const deSelect = () => {
		setModal(false);
		setSelected(null);
		setTimeout(()=>{ setShowBackDrop(false) },50)
	}

	const option = (operation) =>{

		if (operation === 'open'){
			window.open(`/document/${url}/${selected.contractNumber}`)

		} else if (operation === 'edit'){

			setStatus('pending_edit');

			fetch('/api/files/set_file/', {
					method:'POST',
					body:JSON.stringify({
						contract_num:selected.contractNumber,
						file_name:newName
					})
				})	
				.then(res=> {
					getList();
					setStatus('true_edit');
					setTimeout(()=>{setStatus(null)}, 1500);
					setTimeout(()=>{deSelect()}, 2500);
				})
				.catch(err=>{
					setStatus('false');
					setTimeout(()=>{setStatus(null)}, 3000);
				})	

				setNewName('');

		} else {

			setStatus('pending_delete');

			fetch(`/api/files/remove_file/?contract_num=${selected.contractNumber}`, {method:'DELETE'})	
				.then(res=> {
					if(!res){
						setStatus('false');
						setTimeout(()=>{setStatus(null)}, 3000);
					} else {
						getList();
						setStatus('true_delete');
						setTimeout(()=>{setStatus(null)}, 1500);
						setTimeout(()=>{deSelect()}, 2500);
					}
				})
				.catch(err=>{
					setStatus('false');
					setTimeout(()=>{setStatus(null)}, 3000);
				})

		}
	}

	useEffect(() => {
		getList();
	}, [])

	return (
		<div className="folder_main">

			<div className={`back-drop ${showBakcDrop && 'show-backdrop'}`}></div> 
			<div className={`modal app-flex ${modal && 'show_modal'}`} style={{gap:'15px'}}>
				{status  === 'pending_edit' &&  <h3 className="wait">Editing....</h3>}
				{status === 'true_edit' &&  <h3 className="success">File seccussfully Edited</h3>}

				{status  === 'pending_delete' &&  <h3 className="wait">Deleting....</h3>}
				{status === 'true_delete' &&  <h3 className="success">File seccussfully deleted</h3>}
				{status === 'false' &&  <h3 className="err">Error deleting/Editing the files, please try again.</h3>}

				{status === 'toEdit' 
					&& <div style={{width:'100%'}} className="app-flex f-column">
							<MdOutlineKeyboardBackspace className="cross-icon" style={{alignSelf:'flex-start'}} onClick={()=>setStatus(null)}/>
							<span className="search app-flex">
								<input type="Input" placeholder="Entrez un Nouveau Nom" className="search-input" value={newName} onChange={(e)=>setNewName(e.target.value)}/>
								<MdDriveFileRenameOutline className="icon_m"/>
							</span>
							<button className="btn full pointer" onClick={()=>option('edit')}>
								<b>Update</b>
							</button>
						</div>
				}

				{status === null &&
					<>
						<RxCross2 className="cross-icon" onClick={()=>deSelect()}/>
						<h5 className="text app-flex full">{selected?.fileName}</h5>
						<button className="btn full pointer app-flex" style={{gap:'10px'}} onClick={()=>option('open')}> 
							<CgArrowsExpandUpRight className="icon_m"/>
							<b>Ouvrir</b>
						</button>

						<button className="btn full pointer app-flex" style={{gap:'10px'}} onClick={()=>setStatus('toEdit')}>
							<AiOutlineEdit className="icon_m"/>
							<b>Modifier</b>
						</button>

						<button className="btn full pointer app-flex" style={{gap:'10px'}} onClick={()=>option('delete')}>
							<AiOutlineDelete className="icon_m"/>
							<b>Supprimer</b>
						</button>
					</>}
			</div>

			<div className="app-flex head">

				<h4 className="title" style={{marginRight:'auto'}}>
				   {title} Sauvegardés
				</h4>

			</div>
			 
			<div className="app-flex files2_container">
					{data?.length > 0 
					 ? data.map((file,i)=>(
							<div className="folder app-flex" key={i} onClick={()=>select(file)}>
									<LuFile className="f_icon"/>
									<h4 className="f_name">{file.fileName}</h4>
							 </div>

							))
					 : <p>You have no folders yet.</p>}
			</div>
		</div>
	)
}

export default Contracts_List