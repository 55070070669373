import React,{useState, useEffect} from 'react';
import './Facture.css';

import logo from '../../assets/logo.png';
import {useLocation} from 'react-router-dom';
import Table, {RegularCell, Cell} from '../Table/Table';
import Articles from '../Articles/Articles';

import fetch from '../../context/useFetch'

const Facture = () => {
	const location = useLocation();
	let con_num = location.pathname.split('/')[3];
	let toAdd = 0;

	const [client, setClient] = useState(null);
	const [contract, setContract] = useState(null);
	const [details, setDetails] = useState(null);

	let month = parseInt(details?.pay_date.split('/')[1]);

	const doesExit = async (contract_number) => {

		let request = await fetch(`/api/files/get_file/?contract_num=${contract_number}`, {method:'GET'})	;

		if(!request.id){

			try{
				fetch('/api/files/set_file/', {
					method:'POST',
					body:JSON.stringify({
						contract_num:con_num,
						file_name:con_num
					})
				})	
				.then(data=> {
					console.log(data)
				})
				.catch(err=>console.log(err))

			} catch (e){
				console.log(e)
			}

			return true;
		}

		return false;

		
	} 


	useEffect(()=>{
		fetch(`/api/get_contract/?contract_num=${con_num}`, {method:'GET'})
			.then(data=>{
				setClient(data.client);
				setContract(data.contract);
				setDetails(data.details);
				
			})
			.catch(err=>console.log(err))

		doesExit(con_num)

	},[con_num])


	return (
		<div className="app-flex wrapper full-w main_container contract-font">
			<div className="full-w app-flex info">
				
				<div className="informations app-flex wrapper">
					<h4>Sas au capital de 100 000€</h4>
					<h4>26 Rue des Ormeteaux</h4>
					<h4>78570 Andresy</h4>
					<h4>07.67.75.47.51</h4>
					<h4>www.nextcars.fr</h4>
					<h4>contact@nextcars.fr</h4>
					<h4>Siret : 833377997 Rcs Versailles</h4>
				</div>
				<img src={logo} className="top-logo" alt="logo"/>

			</div>

			<div className="heading full-w app-flex">
				<h3>CONTRAT  DE  LOCATION  DE  MATÉRIEL  {contract?.contractNumber} du {contract?.date}</h3>
			</div>
			<div className="app-flex wrapper full-w pagebreak">
				<h4>
					DESIGNATION DU LOCATAIRE :
				</h4>
				<div className="full-w app-flex wrapper" style={{marginLeft:'30px'}}>	
					<b className="start upspace" >{client?.name}</b>
					<p className="text" style={{marginTop:'8px'}}>{details?.location.split('&&')[2]}</p>
					<p className="text">{details?.location.split('&&')[1]}, {details?.location.split('&&')[0]} </p>
					<p className="text">{details?.rc_number} R.C.S. {details?.location.split('&&')[0].split(' ')[0]} </p>
					<p className="text">{client?.phone === null ? "-" : client?.phone}</p>
					<p className="text">{client?.email === null ? "-" : client?.email}</p>
				</div>
				<span className="notice upspace">
					<p>Il a été convenu et arrêté ce qui suit : le bailleur donne en location au locataire qui accepte l’équipement désigné ci-dessous, 
						aux conditions générales et particulières ci-après dont il a pris préalablement connaissance
					</p>
				</span>
				<h4 >
					CONDITIONS PARTICULIERE :
				</h4>
				<div className="inbtwnspace split full-w app-flex">
					
						<Table>
							<RegularCell label="Marque" data={details?.brand}/>
							<RegularCell label="Numero Immatriculation" data={contract?.contractNumber}/>
							<RegularCell label="Heure ou kilometres depart" data={details?.distance_time}/>
							<RegularCell label="Equipement" data={details?.equipement}/>
						</Table>
						<Table>
							<RegularCell label="Modele" data={details?.model}/>
							<RegularCell label="Numero Serie" data={details?.serie}/>
							<RegularCell label="Puissance-PTC-PTRC-Volume" data={details?.volume === null ? '-' : details?.volume}/>
							<RegularCell label="Date premiere Immatriculation" data={details?.registration_date}/>
						</Table>

				</div>
				<h4 >
					DURÉE DE LA LOCATION :
				</h4>
				<div className="full-w app-flex wrapper" style={{marginLeft:'30px'}}>	
					<p className="text" style={{marginTop:'8px'}}>La durée de la location est de : &nbsp;&nbsp;&nbsp; {contract?.duration} mois</p>
					<p className="text">Elle débutera le :  &nbsp;&nbsp;&nbsp; {contract?.date}</p>
					<p className="text">Elle se terminera au terme du :  &nbsp;&nbsp;&nbsp;{contract?.duration} ème loyer</p>
				</div>
				<div className="inbtwnspace split full-w app-flex">
					<table cellPadding="0" cellSpacing="0" style={{width:'98%'}}>
						<tbody>
							<tr className="data-cells">
										<td className="table-keys cell" style={{width:'40%'}}>1er Versement </td>
										<td className="value cell" style={{width:'30%'}}>{contract?.first_pay}€ <b>(HT)</b></td>
										<td className="value cell" style={{width:'30%'}}>{(parseFloat(contract?.first_pay) + parseFloat( (20 * parseFloat(contract?.first_pay))/100 )).toFixed(3)}€ <b>(TTC)</b></td>
							</tr>
							<tr className="data-cells">
										<td className="table-keys cell" style={{width:'40%'}}>{contract?.duration} Loyers courant </td>
										<td className="value cell" style={{width:'30%'}}>{contract?.loyerPrice}€ <b>(HT)</b></td>
										<td className="value cell" style={{width:'30%'}}>{( parseFloat(contract?.loyerPrice)+parseFloat( (20 * parseFloat(contract?.loyerPrice))/100 ) ).toFixed(3)}€ <b>(TTC)</b></td>
							</tr>
						</tbody>
					</table>
				</div>
				<div className="full-w app-flex wrapper" style={{marginLeft:'30px'}}>	
					<h5 style={{alignSelf:"flex-start"}}>
						DURÉE DE LA LOCATION :
					</h5>
					<p className="text">Le présent contrat inclue une utilisation mensuelle forfaitaire de :  &nbsp;&nbsp;&nbsp;40 000 kms</p>
					<p className="text">En cas de restitution anticipée, les dépassements seront facturés :  &nbsp;&nbsp;&nbsp;0,10 €/km excédentaire</p>
				</div>
				<div className="full-w app-flex wrapper" style={{marginLeft:'30px'}}>	
					<h5 style={{alignSelf:"flex-start"}}>
						ASSURANCE :
					</h5>
					<b className="text">Perte  financière </b>
					<p className="text">Ce contrat inclus une assurance perte financière qui couvre, en cas de destruction total du matériel, le delta de remboursement entre la valeur vénale à dire d'expert et le solde de financement restant dû.</p>
					<p className="text">Fait à Paris le {contract?.date} en deux exemplaires dont un pour chacune des parties.</p>
				</div>
				<div className="app-flex full-w" style={{marginLeft:'30px'}}>
					<div className="full-w app-flex wrapper" >	
						<h5 style={{alignSelf:"flex-start"}}>
							LE LOCATAIRE :
						</h5>
						<p className="text">(Cachet et signature précédée de la mention
						  « lu et approuvé »)
						</p>
						
					</div>
					<div className="full-w app-flex wrapper" >	
						<h5 style={{alignSelf:"flex-start"}}>
							LE BAILLEUR :
						</h5>
						<p className="text">&nbsp;&nbsp;&nbsp;&nbsp;</p>
						
					</div>
				</div>
			</div>


			<div className="heading full-w app-flex">
				<h3>FACTURE ET ECHEANCIER</h3>
			</div>
			<div className="app-flex wrap full-w pagebreak">
				
				<div className="app-flex full-w">
					<img src={logo} className="top-logo" alt="logo" style={{width:'200px'}}/>

					<div className="app-flex wrapper" style={{marginLeft:'auto'}}>	
						<b className="start upspace" >{client?.name}</b>
						<p className="text" style={{marginTop:'8px'}}>{details?.location.split('&&')[2]}</p>
						<p className="text">{details?.location.split('&&')[1]}, {details?.location.split('&&')[0]} </p>
						<p className="text">{details?.rc_number} R.C.S. {details?.location.split('&&')[0].split(' ')[0]} </p>
						<p className="text">{client?.phone === null ? "-" : client?.phone}</p>
						<p className="text">{client?.email === null ? "-" : client?.email}</p>
					</div>
				</div>

				<Table>
							<RegularCell label="Contrat n°" data={contract?.contractNumber}/>
							<RegularCell label="Date" data={contract?.date}/>
							<RegularCell label="Objet" data={contract?.contractNumber}/>
				</Table>
				<span className="notice">
					<p>
						Ce calendrier vaut FACTURE. Il ne vous sera adressé ultérieurement aucune facture. Notre société ayant opté pour le paiement de la TVA sur les encaissements, votre droit à déduction ne prend naissance qu'au fur et à mesure du paiement des échéances. Règlement par prélèvement sur votre compte bancaire.	
					</p>
					<p>* TVA au taux en vigueur à la date d’édition soit 20,00%. Intérêt de retard au taux de 10% (article 5 des CG)</p>
				</span>
				<Table>
					<tr className="data-cells" style={{fontWeight:'Bold'}}>
							<td className="table-keys cell2">Numero loyer</td>
							<td className="value2 cell2">Date facture</td>
							<td className="value2 cell2">Date prelevement</td>
							<td className="value2 cell2">Loyer HT</td>
							<td className="value2 cell2">Total TTC</td>
							<td className="value2 cell2">TVA</td>

					</tr>

					{contract?.duration && Array.from(Array(parseInt(contract?.duration)).keys()).map((i)=>{

					if(month >= 12){
						toAdd++;
						month = 0;
					} 

					if(i > 0){
						month++
					}
					if(month < 10){
						month = `0${month}`;
					}

					let day = parseInt(details?.pay_date.split('/')[0]);
					let year = parseInt(details?.pay_date.split('/')[2]) + toAdd;

					let calcTva = (20 * parseFloat(details?.loyer))/100;
					let calcTts = calcTva + parseFloat(details?.loyer);
					

					return <Cell  data={{numero_contract:i+1, numero_rc:`${day}/${month}/${year}`, date:`${day}/${month}/${year}`, loyer_ht:details?.loyer + ' €', duration:calcTts.toFixed(2) + ' €', note:calcTva.toFixed(2) +' €'}} point={false} key={i}/>
					})}			

				</Table>
			</div>

			<div className="full-w app-flex info">
				
				<div className="informations app-flex wrapper">
					<h4>Sas au capital de 100 000€</h4>
					<h4>26 Rue des Ormeteaux</h4>
					<h4>78570 Andresy</h4>
					<h4>07.67.75.47.51</h4>
					<h4>www.nextcars.fr</h4>
					<h4>contact@nextcars.fr</h4>
					<h4>Siret : 833377997 Rcs Versailles</h4>
				</div>
				<img src={logo} className="top-logo" alt="logo"/>

			</div>

			<div className="heading full-w app-flex">
				<h3>MANDAT DE PRELEVEMENT SEPA</h3>
			</div>
			<div className="app-flex full-w wrap pagebreak">
				<span className="notice full-w" style={{width:'98%'}}>
					<b className="start upspace" >{client?.name}</b>
					<p>
						Remplissez ci-dessous les informations concernant le débiteur
					</p>
					<p>JOIGNEZ OBLIGATOIREMENT VOTRE RELEVE D’IDENTITE BANCAIRE</p>
				</span>
				<div className="full-w" style={{width:'98%'}}>
					<b className="start upspace" >Référence unique du mandat : {contract?.contractNumber}</b>
					<p className='upspace'>
						En signant ce formulaire de mandat, vous autorisez la société 
						Nextcars à envoyer des instructions à votre banque pour débiter
						 votre compte conformément à ses instructions. Vous bénéficiez 
						du droit d'être remboursé par votre banque selon les conditions
						 décrites dans la convention que vous avez passée avec elle.
						  Une demande de remboursement doit être présentée dans les 8 
						  semaines suivant la date de débit pour un prélèvement autorisé et
						   sans tarder et au plus tard dans les 13 mois suivant la date de
						    débit pour un prélèvement non autorisé.
					</p>
				</div>
				<div className="full-w" style={{width:'98%'}}>
					<b className="start upspace" >NOM ET ADRESSE DU CREANCIER</b>
					<p className="text" style={{marginTop:'8px'}}>NEXTCARS</p>
					<p className="text">Sas au capital de 100 000€</p>
					<p className="text">26 Rue des Ormeteaux</p>
					<p className="text">78570 Andresy</p>
					<p className="text">07.67.75.47.51</p>
					<p className="text">www.nextcars.fr</p>
					<p className="text">contact@nextcars.fr</p>
					<p className="text">Siret : 833377997 Rcs Versailles </p>
					<p className="text">N° NATIONAL D’EMETTEUR : ICS 79ZZZ87D62A</p>

				</div>
				<div className="full-w app-flex notice" style={{marginLeft:'30px'}}>
					<div className="full-w app-flex wrapper" style={{alignSelf:'flex-start'}}>	
						<b className="start upspace" >{client?.name}</b>
						<p className="text" style={{marginTop:'8px'}}>{details?.location.split('&&')[2]}</p>
						<p className="text">{details?.location.split('&&')[1]}, {details?.location.split('&&')[0]} </p>
						<p className="text">{details?.rc_number} R.C.S. {details?.location.split('&&')[0].split(' ')[0]} </p>
						<p className="text">{client?.phone === null ? "-" : client?.phone}</p>
						<p className="text">{client?.email === null ? "-" : client?.email}</p>
					</div>
					<table cellPadding="0" cellSpacing="0" style={{width:'98%'}}>
						<tbody>
							<tr className="data-cells">
										<td className="table-keys cell" style={{width:'40%', color:'transparent'}}>blank</td>
							</tr>
							<tr className="data-cells">
										<td className="table-keys cell" style={{width:'40%'}}>ETABLISSEMENT TENEUR DU COMPTE </td>
							</tr>
							<tr className="data-cells">
										<td className="table-keys cell" style={{width:'40%', color:'transparent'}}>blank</td>
							</tr>
							<tr className="data-cells">
										<td className="table-keys cell" style={{width:'40%', color:'transparent'}}>blank</td>
							</tr>
							<tr className="data-cells">
										<td className="table-keys cell" style={{width:'40%', color:'transparent'}}>blank</td>
							</tr>

						</tbody>
					</table>
				</div>

				<div className="full-w" style={{width:'98%'}}>
						<b className="start upspace" >NOM ET ADRESSE DU CREANCIER</b>
						<p className="text" style={{marginTop:'8px'}}>IBAN:  &nbsp;&nbsp;&nbsp;&nbsp; {contract?.bci !== null ? contract?.bci : '...........................................................................................................'}</p>
						<p className="text" style={{marginTop:'8px'}}>BIC:  &nbsp;&nbsp;&nbsp;&nbsp;  {contract?.bci !== null ? contract?.bci : '...........................................................................................................'}</p>
				</div>

				<div className="full-w app-flex wrap" style={{width:'98%'}}>
						<b className="start upspace" >LES COORDONNES DE VOTRE COMPTE</b>
						<div className="app-flex start" style={{gap:'50px'}}>
							<p className="text start app-flex" style={{marginTop:'8px', gap:'10px'}}>
								Paiement récurent/répétitif
								<span className="marc">
									x
								</span>
							</p>
							<p className="text start app-flex" style={{marginTop:'8px', gap:'10px'}}>
								Paiement récurent/répétitif
								<span className="marc">
									x
								</span>
							</p>
						</div>
				</div>
				<div className="full-w app-flex wrap" >
					<p className="text notice full-w">Fait à : Paris le {contract?.date}. En deux exemplaires dont un pour chacune des parties</p>
					<div className="app-flex full-w" style={{marginLeft:'30px'}}>
						<p className="text" style={{maxWidth:'300px', width:'100%', marginRight:'auto'}}>
							<i>Signature de la personne autorisée à faire
							Fonctionner le compte</i>
						</p>
						<div className="sign-box">
						
						</div>
					</div>
				</div>
				<div className="full-w app-flex wrap">
					<p className="text">
						Ce mandat de prélèvement SEPA concerne le contrat de la location financière se rapportant au matériel suivant
					</p>
				</div>
				<div className="inbtwnspace split full-w app-flex wrap">
					<p className="text start upspace" style={{marginLeft:'14px'}}>DESCRIPTIF DU MATÉRIEL :	{contract?.contractNumber}</p>
					<div className="inbtwnspace split full-w app-flex">	
						
						<Table>
							<RegularCell label="Marque" data={details?.brand}/>
							<RegularCell label="Numero Immatriculation" data={contract?.contractNumber}/>
							<RegularCell label="Heure ou kilometres depart" data={details?.distance_time}/>
							<RegularCell label="Equipement" data={details?.equipement}/>
						</Table>
						<Table>
							<RegularCell label="Modele" data={details?.model}/>
							<RegularCell label="Numero Serie" data={details?.serie}/>
							<RegularCell label="Puissance-PTC-PTRC-Volume" data={details?.volume === null ? '-' : details?.volume}/>
							<RegularCell label="Date premiere Immatriculation" data={details?.registration_date}/>
						</Table>

					</div>
				</div>
			</div>

			<div className="full-w app-flex info">
				
				<div className="informations app-flex wrapper">
					<h4>Sas au capital de 100 000€</h4>
					<h4>26 Rue des Ormeteaux</h4>
					<h4>78570 Andresy</h4>
					<h4>07.67.75.47.51</h4>
					<h4>www.nextcars.fr</h4>
					<h4>contact@nextcars.fr</h4>
					<h4>Siret : 833377997 Rcs Versailles</h4>
				</div>
				<img src={logo} className="top-logo" alt="logo"/>

			</div>

			<div className="heading full-w app-flex">
				<h3>CONDITIONS GENERALES DE LOCATION DE MATERIEL</h3>
			</div>

			<div className="full-w app-flex wrap pagebreak">
				<Articles/>
				<div className="full-w">
					<p className="text"><i>LE LOCATAIRE (cachet et signature précédé de la mention « lu et approuvé »)</i></p>
					<div className="sign-box">
						
						</div>
				</div>
			</div>

			<div className="full-w app-flex info">
				
				<div className="informations app-flex wrapper">
					<h4>Sas au capital de 100 000€</h4>
					<h4>26 Rue des Ormeteaux</h4>
					<h4>78570 Andresy</h4>
					<h4>07.67.75.47.51</h4>
					<h4>www.nextcars.fr</h4>
					<h4>contact@nextcars.fr</h4>
					<h4>Siret : 833377997 Rcs Versailles</h4>
				</div>
				<img src={logo} className="top-logo" alt="logo"/>

			</div>

			<div className="heading full-w app-flex">
				<h3>PROCES VERBAL DE RECEPTION</h3>
			</div>
			<div className="full-w app-flex wrap pagebreak">

				<div className="notice app-flex full-w">
					<div className="full-w start" style={{width:'50%'}}>
						<b className="start upspace" >Désignation du bailleur :</b>
						<p className="text" style={{marginTop:'8px'}}>NEXTCARS</p>
						<p className="text">26 Rue des Ormeteaux</p>
						<p className="text">78570 Andresy</p>
						<p className="text">833377997 Rcs Versailles </p>

					</div>

					<div className="full-w app-flex start wrapper" style={{width:'50%'}}>	
						<b className="start" >Désignation du locataire :</b>
						<p className="text" style={{marginTop:'8px'}}>{client?.name}</p>
						<p className="text" >{details?.location.split('&&')[2]}</p>
						<p className="text">{details?.location.split('&&')[1]}, {details?.location.split('&&')[0]} </p>
						<p className="text">{details?.rc_number} R.C.S. {details?.location.split('&&')[0].split(' ')[0]}  </p>
						<p className="text">{client?.phone === null ? "-" : client?.phone}</p>
						<p className="text">{client?.email === null ? "-" : client?.email}</p>
					</div>

				</div>	

				<p className="text">Il a été convenu et arrêté ce qui suit : le bailleur donne en location au locataire qui accepte l’équipement désigné ci-dessous, aux conditions générales et particulières ci-après dont il a pris préalablement connaissance</p>
				<div className="inbtwnspace split full-w app-flex wrap">
					<p className="text start upspace" style={{marginLeft:'14px'}}>DESCRIPTIF DU MATÉRIEL :	{contract?.contractNumber}</p>
					<div className="inbtwnspace split full-w app-flex">	
						
						<Table>
							<RegularCell label="Marque" data={details?.brand}/>
							<RegularCell label="Numero Immatriculation" data={contract?.contractNumber}/>
							<RegularCell label="Heure ou kilometres depart" data={details?.distance_time}/>
							<RegularCell label="Equipement" data={details?.equipement}/>
						</Table>
						<Table>
							<RegularCell label="Modele" data={details?.model}/>
							<RegularCell label="Numero Serie" data={details?.serie}/>
							<RegularCell label="Puissance-PTC-PTRC-Volume" data={details?.volume === null ? '-' : details?.volume}/>
							<RegularCell label="Date premiere Immatriculation" data={details?.registration_date}/>
						</Table>

					</div>
				</div>
				<div className="full-w app-flex wrap">
					<b className="text">Lieu de la livraison : &nbsp;&nbsp;&nbsp;26 Rue des Ormeteaux 78570 Andresy  </b>
					<b className="text">INFORMATIONS IMPORTANTES : </b>
					<p className="text">Ce matériel est la propriété de la société :  SAS Nextcars</p>
					<p className="text">En cas de sinistre subi par le matériel, l’indemnité d’assurance devra être versée à :    SAS Nextcars</p>
					<p className="text">En cas de contrôle de police vous devez toujours garder une copie du présent contrat dans le véhicule. La carte grise originale est conservée chez SAS Nextcars . L'arrêté du 28 juillet 2006 portant aménagement de la présentation de la carte grise aux agents de l’autorité compétente autorise la présentation de la photocopie des cartes grises des véhicules en Location Longue Durée.</p>
				</div>
				<div className="full-w app-flex wrap upspace">
					<b className="text">DATE DE MISE À DISPOSITION : </b>	
					<p className="text">Fait à : Paris le {contract?.date} en deux exemplaires dont un pour chacune des parties</p>			
					<div className="app-flex full-w" style={{marginLeft:'30px'}}>
						<div className="full-w app-flex wrapper" >	
							<h5 style={{alignSelf:"flex-start"}}>
								LE LOCATAIRE :
							</h5>
							<p className="text">(Cachet et signature précédée de la mention
							  « lu et approuvé »)
							</p>
							
						</div>
						<div className="full-w app-flex wrapper" >	
							<h5 style={{alignSelf:"flex-start"}}>
								LE BAILLEUR :
							</h5>
							<p className="text">&nbsp;&nbsp;&nbsp;&nbsp;</p>
							
						</div>
					</div>
				</div>
			</div>

			<div className="full-w app-flex info">
				
				<div className="informations app-flex wrapper">
					<h4>Sas au capital de 100 000€</h4>
					<h4>26 Rue des Ormeteaux</h4>
					<h4>78570 Andresy</h4>
					<h4>07.67.75.47.51</h4>
					<h4>www.nextcars.fr</h4>
					<h4>contact@nextcars.fr</h4>
					<h4>Siret : 833377997 Rcs Versailles</h4>
				</div>
				<img src={logo} className="top-logo" alt="logo"/>

			</div>

			<div className="heading full-w app-flex">
				<h3>VALEUR ESTIMÉE EN FIN DE LOCATION</h3>
			</div>
			<div className="full-w app-flex wrap pagebreak">
				<div className="app-flex full-w">
					<div className="full-w start upspace" style={{width:'50%'}}>
						<b className="start upspace" >NEXTCARS</b>
						<p className="text" style={{marginTop:'8px'}}>26 Rue des Ormeteaux</p>
						<p className="text">78570 Andresy</p>
						<p className="text">833377997 Rcs Versailles </p>
					</div>

					<div className="app-flex wrapper" style={{marginLeft:'auto'}}>	
							<b className="start upspace" >{client?.name}</b>
							<p className="text" style={{marginTop:'8px'}}>{details?.location.split('&&')[2]}</p>
							<p className="text">{details?.location.split('&&')[1]}, {details?.location.split('&&')[0]} </p>
							<p className="text">{details?.rc_number} R.C.S. {details?.location.split('&&')[0].split(' ')[0]} </p>
							<p className="text">{client?.phone === null ? "-" : client?.phone}</p>
							<p className="text">{client?.email === null ? "-" : client?.email}</p>
					</div>
				</div>

				<p className="upspace text">La valeur du matériel en fin de location est estimée : <b className="text"> {contract?.caution} € HT </b></p>
				
				<div className="inbtwnspace split full-w app-flex wrap">
					<p className="text start upspace" style={{marginLeft:'14px'}}>DESCRIPTIF DU MATÉRIEL :	{contract?.contractNumber}</p>
					<div className="inbtwnspace split full-w app-flex">	
						
						<Table>
							<RegularCell label="Marque" data={details?.brand}/>
							<RegularCell label="Numero Immatriculation" data={contract?.contractNumber}/>
							<RegularCell label="Heure ou kilometres depart" data={details?.distance_time}/>
							<RegularCell label="Equipement" data={details?.equipement}/>
						</Table>
						<Table>
							<RegularCell label="Modele" data={details?.model}/>
							<RegularCell label="Numero Serie" data={details?.serie}/>
							<RegularCell label="Puissance-PTC-PTRC-Volume" data={details?.volume === null ? '-' : details?.volume}/>
							<RegularCell label="Date premiere Immatriculation" data={details?.registration_date}/>
						</Table>

					</div>
				</div>

				<div className="full-w app-flex wrap upspace">
					<b className="text">DATE DE MISE À DISPOSITION : </b>	
					<p className="text">Fait à : Paris le {contract?.date} en deux exemplaires dont un pour chacune des parties</p>			
					<div className="app-flex full-w" style={{marginLeft:'30px'}}>
						<div className="full-w app-flex wrapper" >	
							<h5 style={{alignSelf:"flex-start"}}>
								LE LOCATAIRE :
							</h5>
							<p className="text">(Cachet et signature précédée de la mention
							  « lu et approuvé »)
							</p>
							
						</div>
						<div className="full-w app-flex wrapper" >	
							<h5 style={{alignSelf:"flex-start"}}>
								LE BAILLEUR :
							</h5>
							<p className="text">&nbsp;&nbsp;&nbsp;&nbsp;</p>
							
						</div>
					</div>
				</div>
			</div>

			<div className="full-w app-flex info">
				
				<div className="informations app-flex wrapper">
					<h4>Sas au capital de 100 000€</h4>
					<h4>26 Rue des Ormeteaux</h4>
					<h4>78570 Andresy</h4>
					<h4>07.67.75.47.51</h4>
					<h4>www.nextcars.fr</h4>
					<h4>contact@nextcars.fr</h4>
					<h4>Siret : 833377997 Rcs Versailles</h4>
				</div>
				<img src={logo} className="top-logo" alt="logo"/>

			</div>

			<div className="heading full-w app-flex">
				<h3>FRAIS ASSOCIÉS AU
					CONTRAT DE LOCATION DE MATÉRIEL {contract?.contractNumber} du {contract?.date}
				</h3>
			</div>
			<div className="full-w app-flex wrap pagebreak">
				<div className="app-flex full-w">
					<div className="full-w start upspace" style={{width:'50%'}}>
						<b className="start upspace" >NEXTCARS</b>
						<p className="text" style={{marginTop:'8px'}}>26 Rue des Ormeteaux</p>
						<p className="text">78570 Andresy</p>
						<p className="text">833377997 Rcs Versailles </p>
					</div>

					<div className="app-flex wrapper" style={{marginLeft:'auto'}}>	
							<b className="start upspace" >{client?.name}</b>
							<p className="text" style={{marginTop:'8px'}}>{details?.location.split('&&')[2]}</p>
							<p className="text">{details?.location.split('&&')[1]}, {details?.location.split('&&')[0]} </p>
							<p className="text">{details?.rc_number} R.C.S. {details?.location.split('&&')[0].split(' ')[0]} </p>
							<p className="text">{client?.phone === null ? "-" : client?.phone}</p>
							<p className="text">{client?.email === null ? "-" : client?.email}</p>
					</div>
				</div>
				
				<table cellPadding="0" cellSpacing="0" style={{width:'98%'}}>
						<tbody>
							<tr className="data-cells">
										<td className="table-keys cell" style={{width:'50%'}}>Prestations</td>
										<td className="value cell" style={{width:'25%', color:'transparent'}}>blank</td>
										<td className="value cell" style={{width:'25%', color:'transparent'}}>blank</td>
							</tr>
							<tr className="data-cells">
										<td className="table-keys cell" style={{width:'50%'}}>Uniquement les amendes de stationnement seront prélevées en plus de la mensualité</td>
										<td className="value cell" style={{width:'25%', color:'transparent'}}>blank</td>
										<td className="value cell" style={{width:'25%', color:'transparent'}}>blank</td>
							</tr>
							<tr className="data-cells">
										<td className="table-keys cell" style={{width:'50%'}}>Frais de Gestion des amendes (stationnement, péages et vitesse)</td>
										<td className="value cell" style={{width:'25%'}}>10,00 €</td>
										<td className="value cell" style={{width:'25%'}}>par amende</td>
							</tr>
							<tr className="data-cells">
										<td className="table-keys cell" style={{width:'50%'}}>Assurance : frais de gestion d'un sinistre</td>
										<td className="value cell" style={{width:'25%'}}>1200,00 €</td>
										<td className="value cell" style={{width:'25%'}}>par sinistre</td>
							</tr>
							<tr className="data-cells">
										<td className="table-keys cell" style={{width:'50%'}}>Frais d'impayés</td>
										<td className="value cell" style={{width:'25%'}}>25,00 €</td>
										<td className="value cell" style={{width:'25%'}}>par impayé</td>
							</tr>
							<tr className="data-cells">
										<td className="table-keys cell" style={{width:'50%'}}>Transfert d'un contrat sur une autre entité</td>
										<td className="value cell" style={{width:'25%'}}>300,00 €</td>
										<td className="value cell" style={{width:'25%'}}>par contrat</td>
							</tr>
							<tr className="data-cells">
										<td className="table-keys cell" style={{width:'50%'}}>Changement de domiciliation bancaire</td>
										<td className="value cell" style={{width:'25%'}}>30,00 €</td>
										<td className="value cell" style={{width:'25%'}}>par contrat</td>
							</tr>
							

						</tbody>
				</table>
				<div className="full-w app-flex wrap upspace">
					<b className="text">DATE DE MISE À DISPOSITION : </b>	
					<p className="text">Fait à : Paris le {contract?.date} en deux exemplaires dont un pour chacune des parties</p>			
					<div className="app-flex full-w" style={{marginLeft:'30px'}}>
						<div className="full-w app-flex wrapper" >	
							<h5 style={{alignSelf:"flex-start"}}>
								LE LOCATAIRE :
							</h5>
							<p className="text">(Cachet et signature précédée de la mention
							  « lu et approuvé »)
							</p>
							
						</div>
						<div className="full-w app-flex wrapper" >	
							<h5 style={{alignSelf:"flex-start"}}>
								LE BAILLEUR :
							</h5>
							<p className="text">&nbsp;&nbsp;&nbsp;&nbsp;</p>
							
						</div>
					</div>
				</div>
			</div>

		</div>
	)
}

export default Facture