import React, {useState, useEffect} from 'react';
import './Grouped.css';

import fetch from '../../../../../context/useFetch';

import Table, {Cell} from '../../../../../components/Table/Table';

const Grouped = () => {

	const [contracts, setContracts] = useState([]);

	useEffect(() => {
		fetch('/api/all_contracts/', {method:'GET'})	
			.then(data=> setContracts(data) )
			.catch(err=>console.log(err))
	}, [])

	return (
		<div className="app-flex full-w wrapper content">
			<h4 className="title">
			  List des contracts
			</h4>
			<Table>
				<tr className="data-cells" style={{fontWeight:'Bold'}}>
						<td className="table-keys cell2">Numero de contract</td>
						<td className="value2 cell2 cell_update">Numero Rc</td>
						<td className="value2 cell2 cell_update">Date</td>
						<td className="value2 cell2 cell_update">Loyer HT <b>(€)</b></td>
						<td className="value2 cell2 cell_update">Duree <b>(mois)</b></td>
						<td className="value2 cell2 cell_update">Prix de Véhicule (€)</td>
						<td className="value2 cell2 cell_update">Bénéfice sur le Véhicule (€)</td>
						<td className="value2 cell2 cell_update">Valeur d'achat</td>
						<td className="value2 cell2 cell_update">Nom de société</td>

				</tr>

				{contracts.length > 0 
				 && contracts.map((contra, i)=>(
					<Cell  data={{numero_contract:contra?.numero_contract, numero_rc:contra?.numero_rc, date:contra?.date, loyer_ht:`${contra?.loyer_ht} €`, duration:contra?.duration, note:contra?.co}} more={[contra?.vehicle_price, contra?.vehicle_profit, contra?.caution]} point={true} key={i}/>
					))
				 }			

			</Table>

			{contracts.length === 0 
			&& <p className="full-w app-flex" 
				 style={{color:'black', backgroundColor:'var(--primary-color)', padding:'10px 0', borderRadius:'var(--radius)'}}>
				 You have no contracts yet.
			</p>}

		</div>
	)
}

export default Grouped