import React, {useState, useEffect} from 'react';
import './Folder.css';
import SubFolder from '../subFolder/subFolder';
import Modal from '../Modal/Modal'

import {LuFileUp, LuFolder} from 'react-icons/lu';
import fetch from '../../context/useFetch';

const Folder = () => {

	const [data, setData] = useState([]);

	const [selectedFolder, setSelectedFolder] = useState('');

	const [showBakcDrop, setShowBackDrop] = useState(false);
	const [modal, setModal] = useState(false);

	const showModal = () => {
		setShowBackDrop(true);
		setTimeout(()=>{ setModal(true) },50)
	}

	const hideModal = () => {
		setModal(false);
		setTimeout(()=>{ setShowBackDrop(false) },50)
	}

	const fetchAttachements = () =>{

		fetch('/api/list_all_attachments/', {method:'GET'})	
				.then(data=> {
					setData(data);				
				})
				.catch(err=>console.log(err))
	}


	useEffect(() => {
		fetchAttachements(); 
	}, [])

	if(selectedFolder !== '') return <SubFolder setSelectedFolder={setSelectedFolder} selectedFolder={selectedFolder} showModal={showModal} fetchAttachements={fetchAttachements}/>

	return (
		<div className="folder_main">

			<Modal modal={modal} showBakcDrop={showBakcDrop} hideModal={hideModal} updateData={false} fetchAttachements={fetchAttachements} fileName={''}/>

			<div className="app-flex head">
				<h4 className="title" style={{marginRight:'auto'}}>
					Vous Fichiers
				</h4>

				<div className="right_margin app-flex" style={{gap:'15px'}}>

					<div className="uploader add  nom app-flex" onClick={()=>showModal()}>
							<LuFileUp className="add-icon"  />
					</div>

				</div>
			</div>
			 
			<div className="app-flex files2_container">
					{data?.length > 0 
					 ? data.map((file,i)=>(

							 <div className="folder app-flex" key={i} onClick={()=>setSelectedFolder(file)}>
									<LuFolder className="f_icon"/>
									<h4 className="f_name">{file.contractNumber}</h4>
							 </div>

							))
					 : <p>You have no folders yet.</p>}
			</div>
		</div>
	)
}

export default Folder