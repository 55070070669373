import axios from "axios";

const uploading = async (file) =>{

    const getAccess = () =>{
        const accessToken = localStorage.getItem("authTokens")
                            ? JSON.parse(localStorage.getItem("authTokens"))
                            : null 
          return accessToken;
        }

    let token = await getAccess();

    const options = {
      method: 'POST',
      url: 'https://api.nextcars.fr:8443/api/get_attachment/',
      params: {attachment: file},
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/pdf',
        authorization: token.access_key
      }
    };

    try{
        const response = await axios.request(options);
        if(response.status === 401){
            localStorage.removeItem("authTokens");
            window.location.href = 'https://dashboard.nextcars.fr/';
            return 0;
          }
        return response
    } catch(e){
        return false
    }
  } 

  export default uploading;