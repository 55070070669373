import React, {useState} from 'react'
import './Modal.css';
import {RxCross2} from 'react-icons/rx';
import {MdDriveFileRenameOutline} from 'react-icons/md';
import {LuFileUp, LuFile} from 'react-icons/lu';

import uploading from '../../context/useUpload';

const Modal = ({showBakcDrop, modal, hideModal, updateData, fileName, fetchAttachements}) => {

	const [file, setFile] = useState(null);
	const [num, setNum] = useState(fileName);
	const [isUploaded, setIsUploaded] = useState(null);

	const clear = () =>{
		setFile(null);
		if(fileName === ''){
			setNum('')
		}
	}

	const handleFileUpload = async (event) =>{

		if(num === null || num?.length < 1){
			setIsUploaded('inc');
			setTimeout(()=>{setIsUploaded(null)}, 3000);
			setFile(null);
			return 0
		}

		setIsUploaded('pending');

		let data = await uploading(file[0], num);
		
		if (data.status === 200){
			fetchAttachements();
			
			if(updateData){
				updateData('add', num, file[0]);
			}
			setIsUploaded('true');
			setTimeout(()=>{setIsUploaded(null)}, 1500);
			setTimeout(()=>{hideModal()}, 2500);
			setFile(null);
		} else {
			
			setIsUploaded('false');
			setTimeout(()=>{setIsUploaded(null)}, 3000);
			setFile(null);
		}

	}

	return (
		<>
			<div className={`back-drop ${showBakcDrop && 'show-backdrop'}`}></div>

			<div className={`modal app-flex ${modal && 'show_modal'}`}>
				{isUploaded  === 'pending' &&  <h3 className="wait">Téléchargement....</h3>}
				{isUploaded  === 'inc' &&  <h3 className="err">Veuillez entrer le nom du dossier.</h3>}
				{isUploaded === 'true' &&  <h3 className="success">Fichier téléchargé avec succès.</h3>}
				{isUploaded === 'false' &&  <h3 className="err">Erreur lors du téléchargement des fichiers, veuillez réessayer.</h3>}

				{isUploaded === null && 
					<>
						<RxCross2 className="cross-icon" onClick={()=>{hideModal(); clear()}}/>
						<span className="search app-flex">
							<input type="Input" placeholder="Entrez Le Nom de Dossier" className="search-input" value={num} onChange={(e)=>setNum(e.target.value)}/>
							<MdDriveFileRenameOutline className="icon_m"/>
						</span>
						
						<label htmlFor="uploader" className="open_uploader add  nom app-flex">
								{file === null 
									? <LuFileUp className="add-icon"  />
									: <span className="upload_on app-flex"><LuFile className="add-icon"/><h3>{file[0].name}</h3></span> 
								}
						</label>
						<input id="uploader" name="uploader" type="file" className="uploader_input" alt="uploader" onChange={(e)=>setFile(e.target.files)}/>

						<button className="btn full pointer" onClick={()=>handleFileUpload()}>
							<b>Upload</b>
						</button>
					</>}
				
			</div>
		</>
	)
}

export default Modal