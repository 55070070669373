import {Routes, Route} from 'react-router-dom';
import { AuthProvider } from "./context/AuthContext";
import PrivateRoute from "./context/PrivateRoute";

import Login from './pages/login/login';
import Main from './pages/main/main';
import Facture from './components/Facture/Facture';
import Devis from './components/Devis/Devis';

import './App.css'

function App() {
  return (
    <section className="App app-flex full-v">
      <AuthProvider>
        <Routes>
          <Route path="/Login" element={<Login/>}/>
          <Route path="/*" element={<PrivateRoute><Main/></PrivateRoute>}/>
          <Route path="/document/facture/*" element={<PrivateRoute><Facture/></PrivateRoute>}/>
          <Route path="/document/devis/*" element={<PrivateRoute><Devis/></PrivateRoute>}/>
        </Routes>
      </AuthProvider>
    </section>
  );
}

export default App;
