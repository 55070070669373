import React, {useState} from 'react';
import './Form.css';
import Message from '../../../../components/Message/Message';
import Loading from '../../../../components/Loading/Loading'

import fetch from '../../../../context/useFetch';

import Table, {InputCell} from '../../../../components/Table/Table';

const Form = () => {

	const [rc, setRc] = useState("");
	const [material, setMaterial] = useState("");
	const [contractDate, setContractDate] = useState("");
	const [pathTime, setPathTime] = useState("");
	const [note, setNote] = useState("");
	const [iban, setIban] = useState("");
	const [bci, setBci] = useState("");
	const [firstPay, setFirstPay] = useState("");
	const [ttc, setTtc] = useState("");
	const [duration, setDuration] = useState("");
	const [numLoyer, setNumLoyer] = useState('1');
	const [factureDate, setFactureDate] = useState("");
	const [datePrelevement, setDatePrelevement] = useState("");
	const [loyerHt, setLoyerHt] = useState("");
	const [totalTtc, setTotalTtc] = useState("");
	const [tva, setTva] = useState("");

	const [vehPrice, setVehPrice] = useState("0");
	const [profite, setProfite] = useState("0");

	const [caution, setCaution] = useState("");

	const [isSent, setIsSent] = useState(false);

	const [message, setMessage] = useState({status:false, msg:null});

	const SubmitForm = () =>{

		let firstPayTtc = (20 * parseInt(firstPay))/100;

		let form ={number_rc:rc, 
				   material_description:material.trim(),
				   contract_date:contractDate,
				   starting_hour_kilometers:pathTime,
				   note:note,
				   iban:iban,
				   bci:bci,
				   first_pay:firstPay,
				   first_pay_ttc:firstPayTtc,
				   duree:duration,
				   number_loyer:numLoyer,
				   date_facture:factureDate,
				   date_prelevement:datePrelevement,
				   loyer_ht:loyerHt,
				   total_ttc: loyerHt,
				   tva: loyerHt,
				   caution:caution};

	  if(!isSent){
	    if(rc !==  "" && material !== "" && contractDate !==  "" && pathTime !==  "" && iban !==  "" && bci !==  "" && note !== ""
	    	&& firstPay !==  "" && duration !==  "" && numLoyer !== "" && factureDate !== "" && datePrelevement !==  "" 
	    	&& loyerHt !== "" && caution !== "" && vehPrice !== "" && profite !== ""){

			setIsSent(true);

			fetch('/api/register_client/', {
						 method:'POST',
						 body:JSON.stringify(form)
						}
					 ).then(async (data)=>{
					 	if(data.message !== 'Invalid material description'){
							await fetch('/api/set_price/',{
										method:'POST',
										body:JSON.stringify({
											contract_num:material.trim(),
											price:vehPrice,
											profit:profite
										})})
						 	setMessage({status:true, msg:'Le contrat a été soumis.'})

						 	setTimeout(()=>{setMessage({...message, status:false})},2000)
						 	setIsSent(false)
						 } else {

						 	setMessage({status:true, msg:'Erreur au numéro SIRET ou Immatriculation.'})

						 	setTimeout(()=>{setMessage({...message, status:false})},2000)
						 	setIsSent(false)
						 }

					 	setRc("");
					 	setMaterial("");
					 	setContractDate("");
					 	setPathTime("");
					 	setIban("");
					 	setBci("");
					 	setFirstPay("");
					 	setTtc("");
					 	setDuration("");
					 	setFactureDate("");
					 	setDatePrelevement("");
					 	setLoyerHt("");
					 	setCaution("")
					 })
					 .catch(err=>console.log(err));
			} else {
				setMessage({status:true, msg:'All field must be filled.'})

				setTimeout(()=>{setMessage({...message, status:false})},2000)
			}
	  }
		
	}

	return (
		<>
		<Message message={message.msg} status={message.status}/>

		<div className="app-flex full-w wrapper content form_container">

			{isSent && <Loading/>}
		
			<h4 className="title">
			  Form d'ajoute
			</h4>

			<Table>
				<InputCell label="Numero SIRET" data={rc} setData={setRc}  value={true}/>
				<InputCell label="Immatriculation" data={material} setData={setMaterial}  value={true}/>
				<InputCell label="Date de contract (JJ/MM/AAAA)" data={contractDate} setData={setContractDate}  value={true}/>
				<InputCell label="Heure our kilometres depart" data={pathTime} setData={setPathTime}  value={true}/>
				<InputCell label="Valeur d'achat" data={caution}  setData={setCaution} value={true}/>
				<InputCell label="Note" data={note}  setData={setNote}/>
				<InputCell label="Iban" data={iban}  setData={setIban}/>
				<InputCell label="Bic" data={bci}  setData={setBci}/>
			</Table>

			<Table>
				<InputCell label="1er versement HT" data={firstPay} setData={setFirstPay}  value={true}/>
				{/*<InputCell label="1er versement TTC" data={ttc} setData={setTtc}  value={true}/>*/}
				<InputCell label="Duree" data={duration} setData={setDuration}  value={true}/>
			</Table>

			<Table>
				<InputCell label="Prix de Véhicule" data={vehPrice} setData={setVehPrice}  value={true}/>
				<InputCell label="Bénéfice sur le Véhicule" data={profite} setData={setProfite}  value={true}/>
			</Table>

			<Table>
				<InputCell label="Numero Loyer" data={"1"} setData={setNumLoyer} />
				<InputCell label="Date facture (JJ/MM/AAAA)" data={factureDate} setData={setFactureDate}  value={true}/>
				<InputCell label="Date prelevement (JJ/MM/AAAA)" data={datePrelevement} setData={setDatePrelevement}  value={true}/>
				<InputCell label="Loyer HT" data={loyerHt} setData={setLoyerHt}  value={true}/>
				{/*<InputCell label="Total TTC" data={totalTtc} setData={setTotalTtc}   value={true}/>
				<InputCell label="TVA" data={tva} setData={setTva}  value={true}/>*/}
			</Table>

			<button className="add" onClick={()=>SubmitForm()}>
				Ajouter
			</button>
		</div>
		</>
	)
}

export default Form