
const BASE_URL = `https://api.nextcars.fr:8443`;

const useFetch = async (endpoint, params) => {


   const getAccess = () =>{
    const accessToken = localStorage.getItem("authTokens")
                        ? JSON.parse(localStorage.getItem("authTokens"))
                        : null 
      return accessToken;
    }

  let token = await getAccess();

  const response = await fetch(`${BASE_URL}${endpoint}`, {
              headers: {
                "Content-Type": "application/json",
                authorization :token.access_key
              },
              ...params
            } );

  if(response.status === 401){
    localStorage.removeItem("authTokens");
    window.location.href = 'https://dashboard.nextcars.fr/';
    return 0;
  }

  if(params.method !== 'PATCH' && params.method !== 'DELETE'){
    
    const data = await response.json();
            
    return data;
  }else {
    console.log(response)
    if(response.status === 200){
      return true
    }else {
      return false
    }
  }

};

export default useFetch;