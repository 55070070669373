import React from 'react';
import './Message.css';

import {GrStatusGood} from 'react-icons/gr';

const Message = ({message, status}) => {
	return (
		<div className="main_msg app-flex shadow" style={{right:`${status ? '10px' : '-1000px'}`}}>
			<GrStatusGood style={{color:'red', fontSize:'1.2rem'}}/>
				{message}
		</div>
	)
}

export default Message