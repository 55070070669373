import React , {useState, useEffect} from 'react';
import './Individuals.css';
import {useNavigate, useLocation} from 'react-router-dom';
import Table, {Cell, RegularCell, InputCell} from '../../../../../components/Table/Table';

import {RiSaveLine, RiDeleteBin5Line} from 'react-icons/ri';
import {HiOutlineSave} from 'react-icons/hi';

import fetch from '../../../../../context/useFetch';

const Individuals = () => {
	const navigate = useNavigate();
	const location = useLocation();
	let con_num = location.pathname.split('/')[2];
	let toAdd = 0;

	const [client, setClient] = useState(null);
	const [contract, setContract] = useState(null);
	const [details, setDetails] = useState(null);

	const [message, setMessage] = useState({status:false, msg:'success'})

	const [email, setEmail] = useState(null);
	const [phone, setPhone] = useState(null);

	const [marque, setMarque] = useState(null);
	const [immatri, setImmatri] = useState(null);
	const [heurePath, setHeurePath] = useState(null);
	const [equipement, setEquipement] = useState(null);
	const [modele, setModele] = useState(null);
	const [serie, setSerie] = useState(null);
	const [volume, setVolume] = useState(null);
	const [regDate, setRegDate] = useState(null);

	const [caution, setCaution] = useState(null);
	const [loyer, setLoyer] = useState(null);
	const [duration, setDuration] = useState(null);
	const [date, setDate] = useState(null);

	const [note, setNote] = useState(null);
	const [iban, setIban] = useState(null);
	const [bci, setBci] = useState(null);

	const [vehiclePrice, setVehiclePrice] = useState(null);
	const [profit, setProfit] = useState(null);



	let month = parseInt(details?.pay_date.split('/')[1]);

	const deleteContract =()=>{
		fetch(`/api/delete_contract/?contract_num=${con_num}`, {method:'DELETE'})
			.then((data)=>{

				if(data){
					setMessage({status:true, msg:'Contract has been deleted.'});

					setTimeout(()=>{setMessage({...message, status:false})},2000);
					navigate('/contracts');
				}
				else {
					setMessage({status:true, msg:'cannot delete contract.'});

					setTimeout(()=>{setMessage({...message, status:false})},2000);
				}
			}) 
			.catch((err)=>console.log(err));
	}

	const updatePrices = () => {
		try{
		   fetch('/api/set_price/',{
					method:'POST',
					body:JSON.stringify({
							contract_num:immatri,
							price:vehiclePrice,
							profit:profit
					})})
			return true
		} catch (e){
			setMessage({status:true, msg:'erreur lors de la mise à jour des prix/profite de Véhicule.'});

			setTimeout(()=>{setMessage({...message, status:false})},2000);
			return false
		}

	}

	const updateContract = () =>{
		fetch(`/api/update_contract/`, {
				method:'PATCH',
				body:JSON.stringify({
					num_contract:con_num,
					vehicle: {
						marque : marque,
						type : null,
						startingDistanceTime: heurePath,
						equipement : equipement,
						model : modele,
						serie : serie,
						volume : volume,
						first_register: regDate
					},
					client: {
						name : client?.name,
						email : email,
						phone : phone,
						location : details?.location
					},
					contract: {
			            caution: caution,
			            date_facture: date,
			            duration: duration,
			            loyer_ht: loyer,
			            iban:iban,
			            bci:bci
			        }
				}) 
			})
			.then(async (data)=>{
				
				if (data){
					await updatePrices();

					setMessage({status:true, msg:'success'});

					setTimeout(()=>{setMessage({...message, status:false})},2000);
				} else {
					setMessage({status:true, msg:'error'});

					setTimeout(()=>{setMessage({...message, status:false})},2000);
				}
			})
			.catch(err=>console.log(err));
	}

	useEffect(()=>{
		fetch(`/api/get_contract/?contract_num=${con_num}`, {method:'GET'})
			.then(data=>{
				setClient(data.client);
				setContract(data.contract);
				setDetails(data.details);

				setEmail(data?.client?.email);
				setPhone(data?.client?.phone);

				setMarque(data.details.brand);
				setImmatri(data.contract.contractNumber);
				setHeurePath(data.details.distance_time);
				setEquipement(data.details.equipement);

				setModele(data.details.model);
				setSerie(data.details.serie);
				setRegDate(data.details.registration_date);

				setCaution(data.contract?.caution);
				setLoyer(data?.details?.loyer);
				setDuration(data?.contract?.duration);
				setDate(data?.details?.pay_date);

				setNote(data?.contract.note);
				setIban(data?.contract.iban);
				setBci(data?.contract.bci);

				data.details.volume === null ? setVolume('-') : setVolume(data.details.volume);
			})
			.catch(err=>console.log(err))

			fetch(`/api/get_price/?contract_num=${con_num}`, {method:'GET'})
				.then((res)=>{
					
					if(res.contract_num){
						setVehiclePrice(res.price);
						setProfit(res.profit);

					}  else{
						setVehiclePrice('-');
						setProfit('-');
					}
				})
				.catch(err=>console.log(err))
	},[con_num])


	return (
		<div className="app-flex full-w wrapper content">

			<div className="header-details app-flex full-w">
				<div className="app-flex" style={{gap:'10px'}}>
					<button className="add" onClick={()=>navigate('/contracts')}>
					  Retour
					</button>
					<button className="add nom app-flex" onClick={()=>deleteContract()}>
					  <RiDeleteBin5Line className="add-icon"/>
					</button>
					<button className="add nom app-flex" onClick={()=>updateContract()}>
					  <RiSaveLine className="add-icon"/>
					</button>
				</div>
				<div className="app-flex" style={{marginLeft:'auto', gap:'10px'}}>
					<button className="add nom app-flex" style={{gap:'5px'}}  onClick={()=>window.open(`/document/devis/${con_num}`)}>
					  <HiOutlineSave className="add-icon"/> Devis
					</button>
					<button className="add nom app-flex" style={{gap:'5px'}}  onClick={()=>window.open(`/document/facture/${con_num}`)}>
					  <HiOutlineSave className="add-icon"/> Contrat
					</button>
				</div>
			</div>

			{message.status && 
				<div className="app-flex msg">
					{message.msg === 'success' 
						? <div className="app-flex success-msg"><p>Le contrat a été mis à jour.</p></div>
						: <div className="app-flex error-msg"><p>Ne peut pas mettre à jour le contrat.</p></div>}
				</div>
			}

			<Table>
				<RegularCell label="Numero SIRET" data={client?.rcNumber}/>
				<RegularCell label="Name" data={client?.name}/>
				<RegularCell label="Loaction" data={`${details?.location.split('&&')[1]}, ${details?.location.split('&&')[0]} - ${details?.location.split('&&')[2]}`}/>
				<InputCell label="Telephone" data={phone} value={true} setData={setPhone}/>
				<InputCell label="Email" data={email} value={true} setData={setEmail}/>
				<InputCell label="Valeur d'achat" data={caution} value={true} setData={setCaution}/>
				<InputCell label="Date facture" data={date} value={true} setData={setDate}/>
				<InputCell label="Duration" data={duration} value={true} setData={setDuration}/>
				<InputCell label="Loyer HT" data={loyer} value={true} setData={setLoyer}/>
				<RegularCell label="Note" data={note}/>
				<InputCell label="Iban" data={iban} value={true} setData={setIban}/>
				<InputCell label="Bic" data={bci} value={true} setData={setBci}/>
				<InputCell label="Prix de Véhicule (€)" data={vehiclePrice} value={true} setData={setVehiclePrice}/>
				<InputCell label="Bénéfice sur le Véhicule (€)" data={profit} value={true} setData={setProfit}/>
			</Table>
			<div className="split full-w">
				<Table>
					<InputCell label="Marque" data={marque} value={true} setData={setMarque}/>
					<InputCell label="Numero Immatriculation" data={immatri} value={true} setData={setImmatri}/>
					<InputCell label="Heure ou kilometres depart" data={heurePath} value={true} setData={setHeurePath}/>
					<InputCell label="Equipement" data={equipement} value={true} setData={setEquipement}/>

				</Table>
				<Table>
					<InputCell label="Modele" data={modele} value={true} setData={setModele}/>
					<InputCell label="Numero Serie" data={serie} value={true} setData={setSerie}/>
					<InputCell label="Puissance-PTC-PTRC-Volume" data={volume} value={true} setData={setVolume}/>
					<InputCell label="Date premiere Immatriculation" data={regDate} value={true} setData={setRegDate}/>

				</Table>
			</div>
			<Table>
				<tr className="data-cells" style={{fontWeight:'Bold'}}>
						<td className="table-keys cell2">Numero loyer</td>
						<td className="value2 cell2">Date Facture</td>
						<td className="value2 cell2">Date prelevement</td>
						<td className="value2 cell2">Loyer HT</td>
						<td className="value2 cell2">Total TTC</td>
						<td className="value2 cell2">TVA</td>

				</tr>

				{duration !== undefined && duration !== null && Array.from(Array(parseInt(duration)).keys()).map((i)=>{	

					if(month >= 12){
						toAdd++;
						month = 0;
					} 

					if(i > 0){
						month++
					}
					if(month < 10){
						month = `0${month}`;
					}

					let day = parseInt(date.split('/')[0]);
					let year = parseInt(date.split('/')[2]) + toAdd;

					let calcTva = (20 * parseFloat(loyer))/100;
					let calcTts = calcTva + parseFloat(loyer);
					

					return <Cell  data={{numero_contract:i+1, numero_rc:`${day}/${month}/${year}`, date:`${day}/${month}/${year}`, loyer_ht:details?.loyer + ' €', duration:calcTts.toFixed(2) + ' €', note:calcTva.toFixed(2) +' €'}} point={false} key={i}/>		
					})}
			
			</Table>
		</div>
	)
}

export default Individuals;