import { createContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext();

export default AuthContext;

const BASE_URL = 'https://api.nextcars.fr:8443';

  export const AuthProvider = ({ children }) => {

    const [authTokens, setAuthTokens] = useState(() =>
      localStorage.getItem("authTokens")
        ? JSON.parse(localStorage.getItem("authTokens"))
        : null
    );


    const [loading, setLoading] = useState(true);

    const navigate = useNavigate();

    const loginUser = async (loginData) => {
      const response = await fetch(`${BASE_URL}/api/login/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          username:loginData.username,
          password:loginData.password
        })
      });
      
      const data = await response.json();

      if (response.status === 200) {
          setAuthTokens(data);
          localStorage.setItem("authTokens", JSON.stringify(data));

        return true;
      } else {
        return false 
      }
    };
    

    const logoutUser = () => {
      setAuthTokens(null);
      localStorage.removeItem("authTokens");

      navigate('/');
    };

    const contextData = {
      authTokens,
      loginUser,
      logoutUser
    };

    useEffect(() => {
      setLoading(false);
    }, [loading]);

    return (
      <AuthContext.Provider value={contextData}>
        {loading ? null : children}
      </AuthContext.Provider>
    );
  };