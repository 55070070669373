import axios from "axios";

const uploading = async (file, num) =>{

    const getAccess = () =>{
        const accessToken = localStorage.getItem("authTokens")
                            ? JSON.parse(localStorage.getItem("authTokens"))
                            : null 
          return accessToken;
        }

    let token = await getAccess();
        
    const form = new FormData();
    form.append("file", file);

    const options = {
      method: 'POST',
      url: 'https://api.nextcars.fr:8443/api/upload_attachment/',
      params: {contract_num: num},
      headers: {
        'Content-Type': 'multipart/form-data; boundary=---011000010111000001101001',
        authorization: token.access_key
      },
      data: form
    };

    try{
        const response = await axios.request(options);
        if(response.status === 401){
            localStorage.removeItem("authTokens");
            window.location.href = 'https://dashboard.nextcars.fr/';
            return 0;
          }
        return response
    } catch(e){
        return false
    }
  } 

  export default uploading;